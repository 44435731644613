<template>
  <div>
    <HomePageTop class="HomePageTopBox"></HomePageTop>
    <loginDialog ref="dialog"/>
    <header style="margin-top: -32px">
      <div class="headerBackground">
        <div>{{ $fanyi("「RAKUMART有料会員制度」") }}</div>
        <div>{{ $fanyi("成为有料会員，享受更多优惠") }}</div>
      </div>
    </header>
    <section>
      <div class="sectionHeader">
        <div class="vipGradeListContainer">
          <div class="vipGradeContainer">
            <img src="../../../../assets/tollVipApplyFor/general.png" alt="">
            <div>一般会員</div>
          </div>
          <div class="vipGradeContainer">
            <img src="../../../../assets/tollVipApplyFor/quota.png" alt="">
            <div>定额会員</div>
          </div>
          <div class="vipGradeContainer" style="margin-right:0">
            <img src="../../../../assets/tollVipApplyFor/svip.png" alt="">
            <div style="color: #7D5610;">SVIP</div>
          </div>
        </div>
        <div class="servicesAvailable backgroundF6F6F6">
          <div class="servicesAvailableLabel">手数料</div>
          <div class="item">3% ~ 5%</div>
          <div class="item">０％</div>
          <div class="item">０％</div>
        </div>
        <div class="servicesAvailable">
          <div class="servicesAvailableLabel">商品調査</div>
          <div class="item">X</div>
          <div class="item" style="line-height: 20px">無料 <br/>（回数制限あり）</div>
          <div class="item" style="line-height: 20px">無料 <br/>（制限なし）</div>
        </div>
        <div class="servicesAvailable backgroundF6F6F6">
          <div class="servicesAvailableLabel">画像翻訳</div>
          <div class="item">なし</div>
          <div class="item">商品詳細ページの画像</div>
          <div class="item">商品詳細ページの画像</div>
        </div>
        <div class="servicesAvailable">
          <div class="servicesAvailableLabel">優先処理</div>
          <div class="item">なし</div>
          <div class="item">〇</div>
          <div class="item">〇</div>
        </div>
        <div class="servicesAvailable backgroundF6F6F6">
          <div class="servicesAvailableLabel">オプション費用</div>
          <div class="item cursorPointer colorB4272B" @click="pageJump" style="color: #B4272B">詳細へ</div>
          <div class="item cursorPointer colorB4272B" @click="pageJump" style="color: #B4272B">詳細へ</div>
          <div class="item">個別相談可能</div>
        </div>
        <div class="newServicesAvailable">
          <div>その他</div>
          <div>
            当社は会員様に対して多様なサービスや特別なカスタマイズサービスを提供しています，詳細は《<span
              class="cursorPointer colorB4272B" @click="pageJump">オプション費用</span> 》 をご覧ください。
            記載されていない物について、担当者まで相談可能。
          </div>
        </div>
      </div>
      <div class="sectionFooter">
        <div class="vipOne">
          <div class="vipName">一般会員</div>
          <div class="flex">
            <div>3%-5%</div>
            <div>手数料</div>
          </div>
          <div class="vipExplain">登録後５％から</div>
        </div>
        <div class="vipTwo">
          <div class="vipName">定額会員</div>
          <div class="vipPriceList">
            <div class="vipPriceContainer" v-for="(item,index) in vipList" :key="index"
                 :style="activeIndex===index?'border: 2px solid #B4272B;':''"
                 @click="activeIndex=index">
              <div class="day">{{ item.days }}日</div>
              <div class="flexAndCenter">{{ $fun.yenNumSegmentation(item.pay) }}
                <div> 円</div>
              </div>
              <div v-if="item.agio>0" class="agio">
                節約{{ $fun.yenNumSegmentation(item.agio) }}円
              </div>
              <div v-show="activeIndex===index" class="activeVipDay">
                <img src="../../../../assets/tollVipApplyFor/select.png" alt="">
              </div>
            </div>
          </div>
          <div class="vipExplain">
            ※月に50万円以上の商品を注文されるお客様におすすめです。
          </div>
          <div class="btnContainer">
            <div @click="balancePayTollVipPageJump">{{ $fanyi("立即申请") }}</div>
          </div>
        </div>
        <div class="vipThree">
          <div>SVIP</div>
          <div>平均毎月一定額の仕入れ量を超えるお客様を個別招待させていただきます。</div>
        </div>
      </div>
    </section>
    <footer>
      <div class="footerContainer">
        <div class="ruleTitle">{{ $fanyi("关于规则事项") }}</div>
        <div class="ruleExplain">
          <div>1、{{ $fanyi("目前无论客户等级如何，会费支付完毕及确定后，以后的代理手续费一律为0%") }}。</div>
          <div>2、{{
              $fanyi("申请成功后，从负责人通知确定的当天开始计算天数。收费会员期限不是按月计算，按天数计算。例如，1月有31天，从1/1日开始的话，有效期是1/30天，1/31就不适用了")
            }}。
          </div>
          <div>3、{{ $fanyi("确定为收费会员后，关于未支付的订单，手续费将自动变为0%") }}。</div>
          <div>4、{{ $fanyi("期间因中国的法定假日等原因连续休息5天以上的情况自动加上相应的天数。(例如:国庆节、春节等)") }}。
          </div>
          <div>
            5、{{
              $fanyi("收费会员失效前5天，系统会自动发送通知邮件，但由于某些原因延迟。因为也有无法到达的情况，关于失效期限，请自己管理")
            }}。
          </div>
          <div>
            6、{{
              $fanyi("请注意，关于成为收费会员期间的变更、退款不能接受。因为我们事先预付了手续费(约定)，是可以产生优惠的服务。万一，在交易中发生了什么问题，由弊公司引起的原因的话，将会提出收费会员费返还以外的补偿方案")
            }}。
          </div>
        </div>
      </div>
    </footer>
    <Foot/>
  </div>
</template>

<script>
import HomePageTop from "../../../../components/head/HomePageTop.vue";
import Foot from "../../../../components/foot/Foot.vue";
import loginDialog from "@/components/public/loginDialog";

export default {
  components: {
    HomePageTop,
    loginDialog,
    Foot
  },
  data() {
    return {
      confirmOpenVipDialogVisible: false,
      vipList: [],
      activeIndex: 0,
    }
  },
  created() {
    this.$api.vipList().then((res) => {
      this.vipList = res.data;
    });
  },
  methods: {
    //展示确认开通VIP的弹窗
    balancePayTollVipPageJump() {
      let japan_user_token = localStorage.getItem('japan_user_token');
      if (!this.$store.state.userInfo && japan_user_token == null) {
        this.$refs.dialog.visible = true;
        return
      }
      this.$api.japanGetUserInfo().then((res) => {
        this.$store.commit("userData", res.data);
      });
      this.$fun.routerToPage('/balancePayTollVip?days=' + this.vipList[this.activeIndex].days);
    },
    pageJump() {
      window.open('/option')
    },
  }
}
</script>

<style lang="scss" scoped>
header {
  .headerBackground {
    background: url("../../../../assets/tollVipApplyFor/bg.jpg") no-repeat;
    height: 370px;
    width: 100%;
    min-width: 1400px;
    background-size: 100% 100%;
    padding-top: 152px;

    div:first-child {
      text-shadow: 0 0 27px rgba(0, 0, 0, 0.4);
      color: #FFFFFF;
      font-weight: bold;
      font-size: 48px;
      margin-bottom: 86px;
      text-align: center;
      letter-spacing: 20px;
    }

    div:last-child {
      text-shadow: 0 0 20px rgba(0, 0, 0, 0.4);
      color: #FFFFFF;
      font-size: 20px;
      text-align: center;
      letter-spacing: 10px;
    }
  }

  .headerVipListContainer {
    height: 510px;
    background: #fff;
    justify-content: center;

    .headerVipContainer {
      .left {
        width: 620px;
        height: 410px;
        background: linear-gradient(0deg, #CC5F5A, #F4776E);
        border: 1px solid #B4272B;
        box-shadow: 0 0 36px 4px rgba(205, 95, 90, 0.5);
        border-radius: 30px;
        margin-right: -40px;
        z-index: 1;
        padding: 28px 40px 0;
        box-sizing: border-box;

        .leftHeaderContainer {
          div {
            color: #FFFFFF;
            font-weight: bold;
            font-size: 24px;
            text-align: right;
            padding-right: 98px;
          }

          padding-bottom: 27px;
          border-bottom: 1px solid #fff;
        }

        .leftRightContainer {
          margin-top: 47px;

          .tollVipIntroduceContainer {
            div {
              color: #FFFFFF;
              font-weight: bold;
              font-size: 20px;
              margin-bottom: 40px;
            }

            div:first-child {
              width: 172px;
              text-align: right;
              margin-right: 66px;
            }

            div:last-child {
              text-align: center;
              width: 300px;
            }
          }
        }
      }

      .right {
        width: 268px;
        height: 410px;
        background: #EEEEEE;
        border: 1px solid #000000;
        border-radius: 0 30px 30px 0;
        z-index: 0;
        padding: 28px 40px 0 70px;
        box-sizing: border-box;

        .rightHeaderContainer {
          div {
            color: #333333;
            font-weight: bold;
            font-size: 24px;
            text-align: center;
          }

          padding-bottom: 27px;
          border-bottom: 1px solid #000000;
        }

        .rightRightContainer {
          margin-top: 48px;
          padding: 0 18px;

          div {
            color: #787878;
            font-size: 20px;
            margin-bottom: 40px;
            text-align: center;
          }
        }
      }
    }
  }
}

.holdOrderDialogVisibleContainer {
  /deep/ .el-dialog {
    border-radius: 10px;
    box-sizing: border-box;
  }

  /deep/ .el-dialog__body, /deep/ .el-dialog__header {
    padding: 0;
  }

  /deep/ .el-dialog__close {
    font-size: 19px;
    color: #B4272B;
    font-weight: bold;
  }


  .holdOrderContainer {
    .icon {
      width: 20px;
      height: 20px;
      background: #B4272B;
      border-radius: 50%;
      color: #fff;
      font-weight: bold;
      text-align: center;
      line-height: 20px;
      margin-right: 6px;
    }

    .holdOrderHeaderContainer {
      height: 46px;
      padding-left: 26px;
      line-height: 46px;
      font-size: 16px;
      font-weight: bold;
      border-bottom: 1px solid #DFDFDF;

      .holdOrderHeader {
        color: #333;
      }
    }

    .holdOrderText {
      color: #333333;
      font-weight: bold;
      text-align: center;
      margin: 40px 30px;
    }

    .dialog-footer {
      button {
        width: 120px;
        height: 34px;
        border-radius: 8px;
        padding: 0;
        font-size: 14px;
      }

      button:first-child {
        border: 1px solid #B4272B;
        color: #B4272B;
      }
    }
  }
}

.margin-right-28 {
  margin-right: 28px;
}

.vipListContainer {
  display: flex;
  flex-wrap: wrap;
  width: 1043px;
}

section {
  background: #F6F6F6;
  margin: 0 auto;
  width: 1400px;
  padding: 50px 0 100px 0;

  .sectionHeader {
    border: 1px solid #DFDFDF;
    border-radius: 10px;
    padding: 45px 30px 43px;
    background: #fff;
    margin-bottom: 60px;

    .vipGradeListContainer {
      margin-left: 330px;
      display: flex;

      .vipGradeContainer {
        margin-right: 20px;
        width: 320px;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding-bottom: 20px;

        img {
          margin-bottom: 24px;
        }

        div {
          color: #333333;
          font-weight: bold;
          font-size: 24px;
        }
      }
    }

    .servicesAvailable {
      width: 100%;
      height: 56px;
      display: flex;
      align-items: center;

      .servicesAvailableLabel {
        width: 330px;
        text-align: center;
        line-height: 56px;
        color: #333333;
        font-weight: bold;
        font-size: 16px;
      }

      .item {
        width: 320px;
        line-height: 56px;
        text-align: center;
        color: #333333;
        font-size: 16px;
        margin-right: 20px;
      }

      .item:last-child {
        margin-right: 0;
      }
    }

    .newServicesAvailable {
      width: 100%;
      height: 56px;
      display: flex;
      align-items: center;

      div:first-child {
        width: 330px;
        text-align: center;
        color: #333333;
        font-weight: bold;
        font-size: 16px;
        min-width: 330px;
      }

      div:last-child {
        color: #999999;
        font-size: 16px;
      }
    }

    .backgroundF6F6F6 {
      background: #F6F6F6;
    }
  }

  .sectionFooter {
    display: flex;
    justify-content: space-between;

    .vipOne {
      width: 280px;
      height: 247px;
      background: url("../../../../assets/tollVipApplyFor/bg1.png") no-repeat;
      background-size: 100% 100%;
      padding: 46px 0 37px;
      display: flex;
      flex-direction: column;
      align-items: center;

      .vipName {
        color: #333333;
        font-weight: bold;
        font-size: 24px;
      }

      .flex {
        margin: 30px 0 28px 0;

        div:first-child {
          color: #333333;
          font-weight: bold;
          font-size: 32px;
          margin-right: 5px;
        }

        div:last-child {
          font-size: 20px;
          margin-top: 12px;
        }
      }

      .vipExplain {
        color: #666666;
        font-size: 14px;
      }

    }

    .vipTwo {
      width: 760px;
      height: 512px;
      background: url("../../../../assets/tollVipApplyFor/bg2.png") no-repeat;
      background-size: 100% 100%;
      padding: 46px 30px 50px;

      .vipName {
        color: #B4272B;
        font-weight: bold;
        font-size: 24px;
        margin-bottom: 40px;
        text-align: center;
      }

      .vipPriceList {
        display: flex;
        justify-content: space-between;

        .vipPriceContainer {
          width: 220px;
          height: 187px;
          border-radius: 6px;
          border: 1px solid #DFDFDF;
          padding-top: 20px;
          position: relative;
          cursor: pointer;

          .day {
            color: #B4272B;
            font-weight: bold;
            font-size: 30px;
            text-align: center;
            margin-bottom: 20px;
          }

          .flexAndCenter {
            justify-content: center;
            color: #333333;
            font-weight: bold;
            font-size: 32px;
            margin-bottom: 13px;

            div {
              font-size: 20px;
              font-weight: 400;
              margin-left: 5px;
            }
          }

          .agio {
            color: #999999;
            font-size: 16px;
            text-align: center;
          }

          .activeVipDay {
            position: absolute;
            bottom: 0;
            right: -2px;
            width: 30px;
            height: 24px;
            background: #B4272B;
            border-radius: 6px 0 6px 0;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }
      }

      .vipExplain {
        margin-top: 50px;
        line-height: 20px;
        font-size: 14px;
        color: #666666;
        text-align: center;
        padding-left: 21px;
      }

      .btnContainer {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 36px;

        div {
          width: 220px;
          height: 45px;
          background: #B4272B;
          border-radius: 10px;
          line-height: 45px;
          text-align: center;
          cursor: pointer;
          color: #FFFFFF;
          font-weight: bold;
          font-size: 18px;
        }
      }
    }

    .vipThree {
      width: 280px;
      height: 247px;
      background: url("../../../../assets/tollVipApplyFor/bg3.png") no-repeat;
      background-size: 100% 100%;
      padding: 48px 31px 0 33px;
      text-align: center;

      div:first-child {
        color: #7D5610;
        font-weight: bold;
        font-size: 24px;
        margin-bottom: 43px;
      }

      div:last-child {
        font-size: 14px;
        color: #666666;
        line-height: 20px;
      }
    }
  }

}

footer {
  background: #fff;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 40px 0;
  margin-bottom: 30px;

  .footerContainer {
    width: 1400px;

    .footerRecommendTitle {
      color: #B4272B;
      margin-bottom: 29px;
    }

    .ruleTitle {
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 17px;
      color: #333333;
    }

    .ruleExplain {
      line-height: 24px;

      div {
        color: #333;
      }
    }
  }
}
</style>
